<template>
  <div class="background">
    <v-dialog :fullscreen="$vuetify.breakpoint.mobile"
              :value="editDialogClienteOpened"
              max-width="800px"
              persistent>
      <v-card class="d-flex flex-column"
        :loading="loadingFormCliente">
        <v-card-title>
          Cadastre-se
          <v-spacer/>
          <v-btn icon @click="editDialogClienteOpened=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="py-4 flex-grow-1" v-if="editDialogClienteOpened">
          <v-form ref="form" @submit.prevent="saveCliente" v-model="formClienteValid" lazy-validation>
            <cadastre-se-form v-model="formCliente"/>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn v-show="botoesCadastroClienteVisivel" outlined color="primary" @click="editDialogClienteOpened=false">Cancelar</v-btn>
          <v-btn v-show="botoesCadastroClienteVisivel" color="primary" @click="saveCliente" :disabled="!formClienteValid">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container class="spacing-playground pa-lg-10" fluid>
      <v-card class="d-flex flex-column"
                :loading="loading">
        <v-card-title>
          <div class="logo">
            <app-logo/>
          </div>
          Bem vindo à Inscrição {{ descEvento('T') }}
        </v-card-title>
        <v-divider/>
        <div v-if="!existeCompeticaoativa" :style="`overflow: auto; max-height: calc(100vh - ${190 + ($vuetify.breakpoint.lgAndUp ? 54 : 0)}px)`">
          <v-card-subtitle>
            Olá, no momento não temos nehum <strong>{{descEvento('N')}}</strong>.<br>
            Fique ligado que em breve abriremos novas etapas dos nossos torneios/novos eventos.<br>
            <br>
            Att.<br>
            equipe <strong>Winner Tennis</strong>.
          </v-card-subtitle>
        </div>
        <div v-else :style="`overflow: auto; max-height: calc(100vh - ${190 + ($vuetify.breakpoint.lgAndUp ? 54 : 0)}px)`">
          <v-card-subtitle>
            Para efetuar sua inscrição <strong>{{descEvento('D')}}</strong>, é necessário estar cadastrado(a) em nosso sistema. <br>
            Informe seu CPF e data de nascimento para prosseguir com sua inscrição ou reemitir seu boleto. Caso não esteja cadastrado, preencha o formulário em seguida.<br>
            <div v-if="this.idCompEvento === 2">
              <br>
              <span style="color:red;font-weight:bold;">OBS.:</span> {{regraCopaFuturo}}
            </div>
          </v-card-subtitle>
          <v-divider/>
          <v-card-text class="pa-5 flex-grow-1">
            <v-row>
              <!--autenticação-->
              <v-col cols="12" md="4">
                <v-card class="px-3">
                  <v-row v-if="encontrou===0 || encontrou ===2">
                    <v-col cols="12" color="yellow">
                      <strong><v-icon large>mdi-account</v-icon> Autenticação</strong>
                      <v-divider/>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-form ref="formAutenticacao" @submit.prevent="buscaCliente" v-model="formAutenticacaoValid" lazy-validation>
                        <autenticacao-form :value="autenticacao"/>
                      </v-form>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-btn color="primary" @click="buscaCliente" :disabled="!formAutenticacaoValid">Buscar</v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="encontrou===3">
                    <v-col cols="12">
                      <strong><v-icon large>mdi-account</v-icon> Confira seus Dados.</strong>
                      <v-divider/>
                      <p>
                        <v-icon color="red" small>mdi-alert</v-icon> Mais de um Cliente encontrado com o CPF <strong>{{this.autenticacao.login}}</strong> e data de nascimento <strong>{{this.autenticacao.password}}</strong>.<br>
                        Entre em contato com a administração da Winner!
                      </p>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <strong><v-icon large>mdi-account</v-icon> Confira seus Dados.</strong>
                      <v-divider/>
                      Caso não esteja correto, entre em contato com a administração da Winner para regularizar.
                    </v-col>
                    <v-col cols="12" md="12">
                      <strong>Nome</strong>: {{dadosCliente.current.nome}}
                    </v-col>
                    <v-col cols="12" md="6">
                      <strong>E-mail</strong>: {{dadosCliente.current.email}}
                    </v-col>
                    <v-col cols="12" md="6">
                      <strong>Celular</strong>: {{dadosCliente.current.celular}}
                    </v-col>
                    <v-col cols="12" md="12">                      
                      <strong>Endereço</strong>: {{dadosCliente.current.endereco}}, {{dadosCliente.current.bairro}}, {{dadosCliente.current.cidade}}, {{dadosCliente.current.estado}}, , {{dadosCliente.current.cep}}
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <!--inscrição-->
              <v-col cols="12" md="4">
                <v-card class="px-3" :loading="loadingBuscaInscricao">
                  <v-row v-if="encontrou===1 && inscreveu===0">
                    <v-col cols="12">
                      <strong><v-icon large>mdi-nintendo-game-boy</v-icon> Competições disponíveis</strong>
                      <v-divider/>
                    </v-col>
                    <v-col cols="12">
                      <v-form ref="formInscricao" @submit.prevent="saveInscricao" v-model="formInscricaoValid" lazy-validation>
                        <inscreva-se-form :value="inscricao" @sendIdCompEventoToInscrevase="recebeIdCompEvento($event)" :idCompEvento="this.idCompEvento"/>
                      </v-form>
                      <v-btn color="primary" @click="saveInscricao" :disabled="!formInscricaoValid">Efetivar Inscrição</v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="inscreveu===1">                    
                    <v-col cols="12">
                      <strong><v-icon large>mdi-nintendo-game-boy</v-icon> Inscrição realizada</strong>
                      <v-divider/>
                    </v-col>
                    <v-col cols="12" md="12">
                      <strong>Competição</strong>: {{dadosInscricao.current.descInscricaoCompeticao ? dadosInscricao.current.descInscricaoCompeticao : ""}}
                    </v-col>
                    <v-col cols="12" md="12">
                      <strong>Prova(s)</strong>: {{retornaProvas(dadosInscricao.current.provasFormatada)}}
                    </v-col>
                    <v-col cols="12" md="12">
                      <strong>Valor</strong>: R$ {{dadosInscricao.current.valorInscricao}}
                    </v-col>                 
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <strong><v-icon large>mdi-nintendo-game-boy</v-icon> Inscrições</strong>
                      <v-divider/>
                    </v-col>
                    <v-col cols="12"> 
                      Informe os dados de acesso para prosseguir com a inscrição
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <!--Pagamento-->
              <v-col cols="12" md="4">
                <v-card class="px-3">
                  <v-row v-if="inscreveu===1">
                    <v-col cols="12">
                      <strong><v-icon large>mdi-cash-multiple</v-icon> Pagamento</strong>
                      <v-divider/>
                    </v-col>
                    <v-col cols="12" md="12">
                      <p>
                        <strong>Fatura</strong>: {{dadosInscricao.current.fatura}}
                      </p>
                      <p v-if="parseInt(dadosInscricao.current.idFatura) > 0"> 
                        Após a efetivação da sua inscrição, o boleto será enviado via e-mail/WhatsApp para o e-mail/número que consta no seu cadastro, assim que o mesmo seja registrado junto ao BANCO.
                      </p>
                      <p class="cor-red" v-if="parseInt(dadosInscricao.current.remessa) > 0">
                        Boleto já disponível para pagamento:
                        <v-icon @click="verBoleto(dadosInscricao.current.idFatura)" large color="red">mdi-file-pdf-box</v-icon>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <strong><v-icon large>mdi-cash-multiple</v-icon> Pagamento</strong>
                      <v-divider/>
                    </v-col>                    
                    <v-col cols="12"> 
                      Ao concluir sua inscrição um boleto será gerado e você receberá em seu e-mail e/ou WhatsApp, assim que o mesmo seja registrado no banco.
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <v-divider/>
        <v-card-actions v-if="existeCompeticaoativa">
          <v-spacer/>
          <v-btn color="primary" @click="limparDados">Reiniciar Inscrição</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AppLogo from "../../components/app/AppLogo";
import InscrevaSeForm from "./components/InscrevaSeForm";
import AutenticacaoForm from "./components/AutenticacaoForm";
import CadastreSeForm from "../../components/shared/forms/CadastreSeForm";
import {clienteNovoCadastro} from "../../api/clientes";
import {findInscricaoPorToken, createPorToken as salvarInscricao} from "../../api/inscricoes";
import {findAllCompeticoesAtivasPublica} from "../../api/competicoes";
import {doLoginAsClient as autenticarCliente} from "@/api/auth";
import {ApiError} from "../../api/core";
import * as moment from 'moment';
import {downloadBoleto} from "../../api/relatorios";
import Vue from 'vue';

export default {
  components: {AppLogo, InscrevaSeForm, CadastreSeForm, AutenticacaoForm},
  props: ['idCompEvento'],
  name: "InscrevaSe",
  data() {
    return {
      idCompEventoLocal: this.idCompEvento,
      competicaoAtiva: {},
      existeCompeticaoativa: false,
      formCliente: {},
      editDialogClienteOpened: false,
      inscricao: {},
      autenticacao: {
        login:    "",
        password: ""
      },
      dadosCliente: {
        token: "",
        current: {}
      },
      dadosInscricao: {
        current: {
          descInscricaoCompeticao: "", 
          valorInscricao: null, 
          provasFormatada: [], 
          fatura: "",
          idFatura: null,
        }
      },
      encontrou: 0,
      inscreveu: 0,
      formClienteValid: false,
      botoesCadastroClienteVisivel: true,
      formAutenticacaoValid: false,
      formInscricaoValid: false,
      loading: false,
      loadingFormCliente: false,
      loadingBuscaInscricao: false,
    }
  },
  computed: {
    regraCopaFuturo() {
      return 'Para a Copa Futuro só é permitido o pariticpante com até 14 anos de idade e que não complete 15 anos até 31/12/' + moment().format('YYYY') + '.'
    },
  },
  methods: {
    recebeIdCompEvento(idCompEventoSelecionado){
      this.idCompEventoLocal = idCompEventoSelecionado;
      this.buscaInscricaoPorToken();
      console.log("Evento selecionado:",this.idCompEventoLocal);
    },
    descEvento(local) {
      if (local=="T") return (this.isCircuitoAmizade(this.idCompEvento) ? 'do Circuito Amizade' : this.idCompEvento === 2 ? 'da Copa Futuro' : 'de Eventos da Winner')
      if (local=="D") return (this.isCircuitoAmizade(this.idCompEvento) ? 'no Circuito Amizade' : this.idCompEvento === 2 ? 'na Copa Futuro' : 'nos Eventos') + " da Winner"
      if (local=="N") return (this.isCircuitoAmizade(this.idCompEvento) ? 'torneio Circuito Amizade' : this.idCompEvento === 2 ? 'torneio Copa Futuro' : 'Evento') + " com isncrições abertas"
    },
    isCircuitoAmizade(idComp){
      return (this.idCompEvento === 1 || this.idCompEvento === 23);
    },
    async verificaSeTemCompeticoesAtivas() {
      this.competicaoAtiva = await  findAllCompeticoesAtivasPublica(1, this.idCompEvento, {});
      if (this.competicaoAtiva.elements.length > 0) this.existeCompeticaoativa = true;
      console.log(this.competicaoAtiva);
    },
    async buscaCliente() {
      if (this.autenticacao.login === "" || this.autenticacao.password === "") {
        this.$toast.error("Informe o CPF e a data de nascimento para prosseguir com a inscrição.", {
              timeout: 6000
            });
        return false;
      }
      try {
        this.loading = true;
        this.dadosCliente = await autenticarCliente(
          this.autenticacao.login,
          this.autenticacao.password
        );
        this.encontrou = 1;
      } catch (err) {
        this.encontrou = 2;
        if (err instanceof ApiError) {
          if (err.message === "duplicado"){
            this.encontrou = 3;
          }
        } else {
          this.encontrou = 2;
        }
        
        if (this.encontrou===2){
          this.$swal({
                title: 'Cliente não encontrado, ir para o cadastro?',
                html: `Não encontramos nenhum cadastro para o CPF <strong>${this.autenticacao.login}</strong> e data de nascimento <strong>${this.autenticacao.password}</strong> informados.`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Não, vou revisar os dados informados',
                confirmButtonText: 'Sim, quero me cadastrar'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.editDialogClienteOpened=true;
                }
              });
        }
      } finally {
        this.loading = false;
      }
    },    
    async saveCliente() {
      if(!this.$refs.form.validate()) return;
      try {
        this.loading = true;
        this.loadingFormCliente = true;
        this.botoesCadastroClienteVisivel = false;
        const {dados} = await clienteNovoCadastro(this.formCliente)
        this.$toast.success(`Cadastro efetuado com sucesso. Enviamos um e-mail com alguns informações.`, {
          timeout: 3000
        });
        this.autenticacao.login = dados.cpf;
        this.autenticacao.password = dados.dtNascimento;

        this.buscaCliente();
        this.editDialogClienteOpened = false;
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível realizar o cadastro. Entre em contato com a Winner!.`, {
            timeout: 6000
          })
        }
      } finally {
        this.loading = true;
        this.loadingFormCliente = true;
        this.botoesCadastroClienteVisivel = true;
      }
    },
    async buscaInscricaoPorToken() {
      try {
        this.inscreveu = 1;
        this.loading = true;
        this.loadingBuscaInscricao = true;
        this.dadosInscricao = await findInscricaoPorToken(
          this.dadosCliente.token,
          this.idCompEventoLocal
        );
        if (!this.dadosInscricao.current){
          this.inscreveu = 0;
          this.dadosInscricao.current = {
            descInscricaoCompeticao: "", 
            valorInscricao: null, 
            provasFormatada: [], 
            fatura: "",
            idFatura: null,
          }
        }
      } catch (err) {
        this.inscreveu = 2;
      } finally {
        this.loading = false;
        this.loadingBuscaInscricao = false;
      }
    },
    async saveInscricao() {
      if(!this.$refs.formInscricao.validate()) return;
      try {
        this.loading = true;
        this.loadingFormCliente = true;
        this.loadingBuscaInscricao = true;
        await salvarInscricao(this.dadosCliente.token, this.inscricao)
        this.$toast.success(`Inscrição efetuada com sucesso.`, {
          timeout: 3000
        });
        this.buscaInscricaoPorToken();
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível realizar a inscrição. Entre em contato com a Winner!.`, {
            timeout: 6000
          })
        }
      } finally {
        this.loading = false;
        this.loadingFormCliente = false;        
        this.loadingBuscaInscricao = false;
      }
    },
    retornaProvas(provas){
      if (provas.length == 0) return "--";
      return provas.map(d => d.itens.map(c => c.descricao).join(', ')).join(' e ');
    },
    async verBoleto(idFatura) {
      let item = {
        formatoSaida: "pdf",
        id: idFatura,
        tk: this.dadosCliente.token,
      };
      this.loading = true;
      await downloadBoleto(item);
      this.loading = false;
    },
    limparDados(){
      this.autenticacao.login = "";
      this.autenticacao.password = "";
      this.formCliente = {};
      this.editDialogClienteOpened = false;
      this.inscricao = {};
      this.dadosCliente.token = "";
      this.dadosCliente.current = {};
      this.dadosInscricao = {}
      this.dadosInscricao.current = {}
      this.dadosInscricao.current.descInscricaoCompeticao = "";
      this.dadosInscricao.current.valorInscricao = null;
      this.dadosInscricao.current.provasFormatada = [];
      this.dadosInscricao.current.fatura = "";
      this.dadosInscricao.current.idFatura = null;
      this.encontrou = 0;
      this.inscreveu = 0;
      this.formClienteValid = false;
      this.botoesCadastroClienteVisivel = true;
      this.formAutenticacaoValid = false;
      this.formInscricaoValid = false;
      this.loading = false;
      this.loadingFormCliente = false;
      this.loadingBuscaInscricao = false;
    }
  },
  mounted() {
    this.verificaSeTemCompeticoesAtivas();
  }
}
</script>

<style scoped lang="scss">

.cor-red {
  color: red;
}

.background {
  background-image: url('../../assets/backgrounds/bg01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  flex: 1;

  .logo {
    padding: 5px;

    img {
      height: 60px;
    }
  }

}

</style>