<template>
  <v-row>
    <v-col cols="12" sm="12">
      <search-box
          :emit-all-properties="true"
          label="* Competição"
          popup-label="Selecione uma Competição Ativa"
          :id.sync="value.idCompCompeticao"
          :descricao.sync="value.descInscricaoCompeticao"
          :idCompEvento.sync="value.idCompEvento"
          :provas.sync="value.provasCompeticao"
          :value="competicao"
          :loader-fn="loaderCompeticoesAtivas"
          item-key="id"
          item-text="descricao"
          :rules="[
                this.$validators.string.required,
                this.$validators.notNullOrUndefined
            ]">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhuma Competição Ativa encontrada
          </p>
        </template>
      </search-box>
    </v-col>
    <v-col cols="12" sm="12">
      <!-- {{value.provasCompeticao ? value.provasCompeticao.provas : []}} -->
      <v-row v-if="parseInt(value.idCompEvento) === 1">
        <v-col cols="12" sm="6">
            <strong>WTP RANKING</strong> <v-icon @click="limpaProva(1)" >mdi-reload</v-icon>
            <v-radio-group 
              v-model="prova1"
              dense
              name="prova1"
              column >
              <v-radio label="Grand Slam" color="red" value="1"></v-radio>
              <v-radio label="Winner 1000" color="red" value="2"></v-radio>
              <v-radio label="Winner 500" color="red" value="3"></v-radio>
              <v-radio label="Winner 250" color="red" value="4"></v-radio>
            </v-radio-group>
        </v-col>
        <v-col cols="12" sm="6">
            <strong>SÊNIOR</strong> <v-icon @click="limpaProva(2)" >mdi-reload</v-icon>
            <v-radio-group 
              v-model="prova2"
              :dense="true"
              name="prova2"
              column >
              <v-radio label="Sênior A" color="red" value="1"></v-radio>
              <v-radio label="Sênior B" color="red" value="2"></v-radio>
              <v-radio label="Sênior C" color="red" value="3"></v-radio>
            </v-radio-group>
        </v-col>
        <v-col cols="12" sm="12">
          A prova assinalada acima é meramente indicativa da intenção do jogador.
          <br>
          A prova final que o jogador irá disputar, será definida pelo <strong><a color="red" class="bold" href="http://www.winner.com.br/site/2014-03-26-18-12-57/ranking-2" target="_blank">rancking</a></strong> do circuito amizade. 
        </v-col>
      </v-row>
      <v-row v-else-if="parseInt(value.idCompEvento) === 23">
        <v-col cols="12">
          <strong>CLASSES:</strong>
        </v-col>
        <v-col cols="6">
          <v-checkbox dense v-model="circuitoAmizadeNovo.classesSelecionadas" label="1a. Classe" value="1" :error="false" class="checkbox-custom-spacing"></v-checkbox>
          <v-checkbox dense v-model="circuitoAmizadeNovo.classesSelecionadas" label="2a. Classe" value="2" :error="false" class="checkbox-custom-spacing"></v-checkbox>
          <v-checkbox dense v-model="circuitoAmizadeNovo.classesSelecionadas" label="3a. Classe" value="3" :error="false" class="checkbox-custom-spacing"></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox dense v-model="circuitoAmizadeNovo.classesSelecionadas" label="4a. Classe" value="4" :error="false" class="checkbox-custom-spacing"></v-checkbox>
          <v-checkbox dense v-model="circuitoAmizadeNovo.classesSelecionadas" label="5a. Classe" value="5" :error="false" class="checkbox-custom-spacing"></v-checkbox>
          <v-checkbox dense v-model="circuitoAmizadeNovo.classesSelecionadas" label="6a. Classe" value="6" :error="false" class="checkbox-custom-spacing"></v-checkbox>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field dense
          label="Valor da Inscrição"
          prefix="R$"
          outlined
          disabled
          v-model="value.valorInscricao" 
          type="number"/>
    </v-col>
  </v-row>
</template>

<script>
import SearchBox from "../../../components/shared/common/SearchBox";
import LookupDominio from "../../../components/shared/common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";
import {findAllCompeticoesAtivasPublica} from "../../../api/competicoes";
import {ApiError} from "../../../api/core";

export default {
  name: "InscrevaSeForm",
  components: {LookupDominio, SearchBox},
  props: {
    value: {
      type: Object
    },
    idCompEvento: {
      type: Number
    },
  },
  data () {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO
      },
      prova1: null,
      tituloProvaWTP: "WTP RANKING",
      provaWTP: [
        {id: "1", descricao: "Grand Slam"},
        {id: "2", descricao: "Winner 1000"},
        {id: "3", descricao: "Winner 500"},
        {id: "4", descricao: "Winner 250"},
      ],
      valor1: 150.00,
      valor2: 200.00,      
      prova2: null,
      tituloProvaSenior: "SÊNIOR",
      provaSenior: [
        {id: "1", descricao: "Sênior A"},
        {id: "2", descricao: "Sênior B"},
        {id: "3", descricao: "Sênior C"},
      ],
      circuitoAmizadeNovo: {
        valor1: 160.00,
        valor2: 220.00,
        classes: [
          {id: "1", descricao: "1a. Classe"},
          {id: "2", descricao: "2a. Classe"},
          {id: "3", descricao: "3a. Classe"},
          {id: "4", descricao: "4a. Classe"},
          {id: "5", descricao: "5a. Classe"},
          {id: "6", descricao: "6a. Classe"},
        ],
        classesSelecionadas: [],
      }
    }
  },
  watch: {
    'prova1': {
      handler(newValue, oldValue) {
        this.configuraProvasEscolhidasESetaValor();
      }
    },
    'prova2': {
      handler(newValue, oldValue) {
        this.configuraProvasEscolhidasESetaValor();
      }
    },
    'circuitoAmizadeNovo.classesSelecionadas': {
      handler(newValue, oldValue) {
        this.configuraProvasEscolhidasESetaValor();
      }
    },
    'value.idCompCompeticao': {
      handler(newValue, oldValue) {
        this.configuraProvasEscolhidasESetaValor();
        this.$emit('sendIdCompEventoToInscrevase',this.value.idCompEvento);
      }
    },
  },  
  methods: {
    loaderCompeticoesAtivas(page, search) {
      return findAllCompeticoesAtivasPublica(page, this.idCompEvento, {
        descricao: search
      })
    },
    configuraProvasEscolhidasESetaValor() {
      let prova = "";
      let contador = 0;
      let idCompEvento = parseInt(this.value.idCompEvento);
      if (idCompEvento == 1){
        if (parseInt(this.prova1) > 0) {
          let obj1 = this.provaWTP.find(option => option.id === this.prova1);
          prova += '1|'+this.tituloProvaWTP+'$'+obj1.id+"|"+obj1.descricao;
          contador++;
        }
        if (parseInt(this.prova2) > 0) {
          let obj2 = this.provaSenior.find(option => option.id === this.prova2);
          prova += (prova !== "" ? "#" : "" )+'2|'+this.tituloProvaSenior+'$'+obj2.id+"|"+obj2.descricao;
          contador++
        }
        if (contador > 1) this.value.valorInscricao = this.valor2;
        else this.value.valorInscricao = this.valor1;
        this.value.provasCad = prova;
      } else if (idCompEvento == 23){

        const { classes, classesSelecionadas } = this.circuitoAmizadeNovo;
        
        let qtdClasses = classesSelecionadas.length;
        console.log(qtdClasses);

        if (!this.validaClasses()){
          return;
        }

        if (qtdClasses === 1) {
          this.value.valorInscricao = this.circuitoAmizadeNovo.valor1;
        } else if (qtdClasses > 1) {
          this.value.valorInscricao = this.circuitoAmizadeNovo.valor2;
        }

        this.value.provasCad =  '1|CLASSES$' + classes
            .filter(classe => classesSelecionadas.includes(classe.id)) // Filtra as classes selecionadas
            .map(classe => `${classe.id} | ${classe.descricao}`) // Formata como "id | descricao"
            .join("&"); // Junta os itens com vírgula

      } else {
        this.value.valorInscricao = parseFloat(this.value.provasCompeticao.valor1);
        this.value.provasCad = "";
      }
    }, 
    validaClasses(){
      const { classesSelecionadas } = this.circuitoAmizadeNovo;
      let qtdClasses = classesSelecionadas.length;
      if (qtdClasses > 2) {
        classesSelecionadas.pop();
        this.$toast.error(`Só é permitido selecionar duas classes.`, {
          timeout: 6000
        });        
        return false;
      } else if (qtdClasses == 2) {
        let classe1 = parseInt(classesSelecionadas[0], 10);
        let classe2 = parseInt(classesSelecionadas[1], 10);
        if (Math.abs(classe1 - classe2) !== 1) {
          classesSelecionadas.pop();
          this.$toast.error(`As classes selecionadas precisam ser consecutivas.`, {
            timeout: 6000
          });
          return false;
        }
      }
      return true;
    },
    limpaProva(id) {
      if (id==1) this.prova1 = null;
      else this.prova2 = null;
    }    
  },
  computed: {
    competicao() {
      if(this.value.idCompCompeticao == null) {
        this.value.descInscricaoCompeticao = null;
        this.value.valorInscricao = null;
        this.value.idCompEvento = null;
        return null
      };
      return {
        id: this.value.idCompCompeticao,
        descricao: this.value.descInscricaoCompeticao,
        valorInscricao: this.value.valorInscricao,
        idCompEvento: this.value.idCompEvento
      }
    },
  },
  mounted() {
  }
}
</script>

<style scoped>
</style>